import {Injectable} from "@angular/core";
import {DateUtils, Publication, PublicationsService} from "@kwsoft/otcx-core";
import {DateTime} from "luxon";
import {combineLatest, Observable} from "rxjs";
import {map, share, switchMap} from "rxjs/operators";
import {FileSaverService} from "ngx-filesaver";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class PriceQuotationService {

    constructor(private readonly publicationsService: PublicationsService,
                private readonly httpClient: HttpClient,
                private readonly fileSaverService: FileSaverService) {
    }

    getLatestPriceQuotation(from: DateTime, until: DateTime): Observable<Publication> {
        return this.publicationsService.getPublications(DateUtils.toApiDate(from), DateUtils.toApiDate(until)).pipe(
            map(publications => publications[0])
        );
    }

    downloadLatestPriceQuotation(from: DateTime, until: DateTime): Observable<void> {
        const priceQuotation$ = this.getLatestPriceQuotation(from, until).pipe(share());
        const blob$ = priceQuotation$.pipe(switchMap(priceQuotation => this.httpClient.get(priceQuotation.link, {responseType: "blob"})));

        return combineLatest([priceQuotation$, blob$]).pipe(
            map(([priceQuotation, blob]) =>
                this.fileSaverService.save(blob, `${priceQuotation.title}.pdf`)
            ));
    }
}
