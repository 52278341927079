import {ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import {
    AnalyticsModule,
    AuthModule,
    BASE_PATH,
    ErrorModule,
    GlobalErrorHandler,
    OtcxCoreModule,
    ScrollRestorationService,
    SharedModule,
    StaticContentModule
} from "@kwsoft/otcx-core";
import {environment} from "../environments/environment";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {LayoutModule} from "./layout/layout.module";
import {HomeModule} from "./home/home.module";
import {RedirectOnlyComponent} from "./redirect-only.component";

@NgModule({ declarations: [AppComponent, RedirectOnlyComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        OtcxCoreModule.forRoot(),
        AuthModule.forRoot(environment),
        SharedModule,
        LayoutModule,
        // NOTE: the styles module contains the styles playground that is used for the style guide and to upgrade styles and so on
        // it should NOT be enabled/included by default. If it is included, the additional paths `/styles` and `/styles/error` are available in the app.
        // StylesModule,
        HomeModule,
        AppRoutingModule,
        StaticContentModule,
        AnalyticsModule.forRoot(environment),
        ErrorModule], providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
    constructor(scrollRestorationService: ScrollRestorationService) {
        scrollRestorationService.init();
    }
}
