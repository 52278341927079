<div class="card">
    <div class="card-header-panel">
        <h4>Kursblätter</h4>
    </div>

    <ul class="card-body-list">
        <li>
            <a href="#" (click)="getLatestPriceQuotation($event)" data-test="latest-download-link">
                <img alt="pdf" src="assets/ico-2d-dokument-pdf-01.svg">
                <span>Aktuelles Kursblatt</span>
            </a>
        </li>
        <li>
            <a href="#" (click)="getLastMonthPriceQuotation($event)" data-test="last-month-download-link">
                <img alt="pdf" src="assets/ico-2d-dokument-pdf-01.svg">
                <span>Letztes Kursblatt Vormonat</span>
            </a>
        </li>
        <li>
            <a href="#" (click)="getLastYearPriceQuotation($event)" data-test="last-year-download-link">
                <img alt="pdf" src="assets/ico-2d-dokument-pdf-01.svg">
                <span>Letztes Kursblatt Vorjahr</span>
            </a>
        </li>
    </ul>
    <div class="card-body-plaintext">
        <div class="form-control" data-test="date-form-control">
            <input [formControl]="dateControl" type="text" class="input" id="date" [ngClass]="{'has-value': !!dateControl.value}">
            <label class="floating" for="date">TT.MM.JJJJ</label>
        </div>
    </div>

    <div class="card-footer-actions">
        <button class="btn btn-primary" [disabled]="submitDisabled" (click)="submit()" data-test="submit-button">Kursblatt suchen</button>
    </div>
    <ng-container *ngIf="displaySearchResult$ | async" >
        <div class="card-footer-actions search-result" *ngIf="priceQuotation$ | async as priceQuotation else notFound" data-test="search-result">
            <a href="{{priceQuotation.link}}" target="_blank" rel="noopener">
                <img alt="pdf" src="assets/ico-2d-dokument-pdf-01.svg">
                <span>{{priceQuotation.title}}</span>
            </a>
        </div>

        <ng-template #notFound>
            <div class="card-body-no-data" data-test="search-result">
                Vom gewünschten Tag existiert leider kein Kursblatt. Bitte versuchen Sie ein anderes Datum
            </div>
        </ng-template>
    </ng-container>
</div>
