<footer>
    <div class="footer-content" [ngClass]="{'with-media': socialMedia.hasLinks}">
        <div *ngIf="address$ | async as address" class="footer-address">
            <h5>Anschrift</h5>
            <div>
                {{address.address}}
            </div>
        </div>
        <div class="footer-about">
            <h5>Über OTC-X</h5>
            <div><a [routerLink]="['about', 'team']" data-test="team-link">Team</a></div>
            <div><a [routerLink]="['about', 'partners']" data-test="partners-link">Partner</a></div>
            <div *ngIf="tradingRegulations$ | async as tradingRegulations">
                <a data-test="regulations-link" href="{{tradingRegulations.file.path}}" rel="noopener"
                   target="_blank">Reglement</a>
            </div>
        </div>
        <div class="footer-contact">
            <h5>Kontakt</h5>
            <div *ngIf="contact$ | async as contact" data-test="contact-mailto">Haben Sie Fragen oder Anregungen? Wir
                freuen uns über Ihre Nachricht an <a href="mailto:{{contact.email}}">{{contact.email}}</a></div>
            <div *ngIf="newsletter$ | async as newsletter"><a data-test="contact-newsletter" href="{{newsletter.link}}"
                                                              target="_blank" rel="noopener">Newsletter anmelden</a>
            </div>
        </div>
        <div class="footer-media">
            <otcx-social-media-links #socialMedia></otcx-social-media-links>
        </div>

        <div class="footer-legal">
            <div class="copyright">&copy; Berner Kantonalbank AG</div>
            <ng-container *ngIf="disclaimer$ | async as disclaimer">
                <div *ngIf="!!disclaimer.url"><a data-test="disclaimer-link" href="{{disclaimer.url}}"
                                                 target="_blank" rel="noopener">Rechtliche Hinweise</a></div>
            </ng-container>
            <ng-container *ngIf="dataPrivacy$ | async as dataPrivacy">
                <div *ngIf="!!dataPrivacy.url"><a data-test="data-privacy-link" href="{{dataPrivacy.url}}"
                                                  target="_blank" rel="noopener">Datenschutz</a></div>
            </ng-container>
            <div><a [routerLink]="'about/legalNotice'" data-test="legal-notice-link">Impressum</a></div>
        </div>
    </div>
</footer>
